import { ThemeProps as Prop } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#ffffff',
  },
  shades: {
    [Prop.LIGHTEST]: '#f0f0f0',
    [Prop.LIGHTER]: '#e0e0e0',
    [Prop.LIGHT]: '#cccccc',
    [Prop.GRAY]: '#8b8b8b',
    [Prop.GRAYER]: '#696969',
    [Prop.GRAYEST]: '#525257',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73c053',
    [Prop.INTERMEDIATE]: '#30a8e0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3b5998',
    [Prop.EVENTBRITE]: '#f6682f',
  },
  status: {
    [Prop.WARNING]: '#f6682f',
    [Prop.SUCCESS]: '#73c053',
    [Prop.ERROR]: '#dd3631',
    [Prop.GO]: '#008000',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#ffb400',
    [Prop.DANGER]: '#fe4a49',
  },
  tv: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#ffffff',
    [Prop.CONTENT]: '#ffffff',
    [Prop.BORDER]: '#ffffff',
    [Prop.ICON]: '#ffd200',
    [Prop.LINK]: '#ffffff',
    [Prop.LINK_HOVER]: '#ffd200',
    [Prop.ODD_EVEN_OFFSET]: '#e5eded',
    [Prop.SIDE_NAV_ITEM_BG]: '#ffd200',
    [Prop.SIDE_NAV_ITEM_TXT]: '#000000',
    [Prop.SIDE_NAV_ITEM_ICON]: '#000000',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#ffffff',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#000000',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#000000',
    [Prop.TOP_NAV_ITEM_TXT]: '#ffffff',
    [Prop.ALERT_MARQUEE_BG]: '#666666',
    [Prop.ALERT_MARQUEE_ALERT_TXT]: '#ffffff',
    [Prop.ALERT_MARQUEE_TEMP_TIME_TXT]: '#ffffff',
    [Prop.ALERT_OVERLAY_BG]: '#000000',
    [Prop.ALERT_OVERLAY_TXT]: '#ffd200',
    [Prop.ALERT_OVERLAY_TEMP_TIME_TXT]: '#ffd200',
    [Prop.LIFTS_TXT]: '#ffffff',
    [Prop.LIFTS_ICON]: '#ffd200',
    [Prop.EVENT_1]: '#ffffff',
    [Prop.EVENT_2]: '#ffffff',
  },
};
