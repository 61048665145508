import { mergeDeepLeft } from 'ramda';

import { importSVGs } from '@powdr/utils';
import baseTheme from '@powdr/woodward-base/theme/theme';

import { config } from './config';

// TODO: Check powdr-ui to see if default component configs live in woodward-shared or per property
// Define our theme overrides
const themeOverrides = {
  svgs: { ...importSVGs(require.context('../assets/svgs', true, /\.svg$/)) },
  // Templete for adding default theme,
  // defaultTheme: {},
  config,
};

// Using Ramda.mergeDeepLeft() to ensure a deep merge that spread doesn't get.
export default mergeDeepLeft(themeOverrides, baseTheme);
