import './font-faces.css';

import { fontTheme } from './fonts';
import {
  defaultTheme, StaticTheme,
} from './themes';

const theme = {
  defaultTheme,
  StaticTheme,
  ...fontTheme,
};

export default theme;
